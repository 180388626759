import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import { LocaleNameSpaceKeys } from '../../localization/translations';
import PageMenu from '../../components/PageMenu/PageMenu';
import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import mortgageResourcesMenu from '../../data/page-menus/mortgage-resources';
import EventInfo from '../../components/EventInfo/EventInfo';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import Link from '../../components/Link/Link';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import CommonData from '../../data/common/common';
import { useLocaleContext } from '../../context/Locale';
import { ButtonLinkSize } from '../../components/ButtonLink/ButtonLink';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import { IconTypes } from '../../components/Icon/Icon';
import MagicCarousel from '../../components/MagicCarousel/MagicCarousel';
import ImportantMessage from '../../components/ImportantMessage/ImportantMessage';
import './style.scss';

type Props = PageProps & WithTranslation;

const CustomerCentre: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('customerCentre.seo.title'),
    },
    // page: {
    //   title: t('customerCentre.heading'),
    // },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-13.jpg',
          alt: 'Image',
        },
        blockText: t('customerCentre.tagline'),
        shouldBeDigiBlock: true,
      },
    },
    shouldEnableMaxWidth: false,
  };

  const { activeLocaleTag } = useLocaleContext();
  const customerServiceSupport = getPathFromRouteKey(RouteKeys.CONTACT_US);

  return (
    <Layout
      options={layoutOptions}
      className="CustomerCentre CustomerCentre__header-max-width-container"
    >
      <div className="grid-container CustomerCentre__max-width-container CustomerCentre__generic-gap">
        <div className="row row-wrap CustomerCentre__generic-gap ">
          <section className="column column-100">
            <div className="CustomerCentre__generic-gap">
              <ImportantMessage />
            </div>
            <h1>{t('customerCentre.heading')}</h1>
          </section>
          <section className="column column-60">
            <div className="CustomerCentre__generic-gap">
              <p>{t('customerCentre.introParagraph')}</p>
            </div>
            <div className="CustomerCentre__generic-gap">
              <h2 className="h5">{t('customerCentre.htbCares.title')}</h2>
              <p>
                <Trans t={t} i18nKey="customerCentre.htbCares.description" />
              </p>
            </div>
            <div className="CustomerCentre__generic-gap">
              <h2 className="h5">{t('customerCentre.weCare.title')}</h2>
              <p>
                <Trans
                  t={t}
                  i18nKey="customerCentre.weCare.description"
                  values={{ phone: CommonData.tollFreeCustomerServiceNumber.mortgageServicing }}
                >
                  <ExternalLink
                    href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                    }`}
                  />
                  <Link to={customerServiceSupport} />
                  <ExternalLink href={CommonData.websites.fcacMortgages[activeLocaleTag]} />
                </Trans>
              </p>
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={mortgageResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.mortgageResources`)}
            />
          </aside>
        </div>
        <div className="CustomerCentre__generic-gap-2 CustomerCentre__info-block-wrapper ">
          <MagicCarousel
            ariaNavItemLabels={[
              t('customerCentre.clientPortal.heading'),
              t('customerCentre.encompass.heading'),
              t('customerCentre.nextJob.heading'),
              t('customerCentre.prepayment.heading'),
              t('customerCentre.higherInterest.heading'),
            ]}
          >
            <div className="row row-wrap">
              <div className="column column-60">
                <InfoBlock
                  icon={IconTypes.LOCK}
                  buttonOptions={{
                    to: getPathFromRouteKey(RouteKeys.BORROWER),
                    label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                    iconOptions: {
                      icon: IconTypes.ARROW_NEXT,
                    },
                    styleOptions: {
                      isInline: true,
                      size: ButtonLinkSize.SMALL,
                    },
                  }}
                  heading={<h2 className="h5">{t('customerCentre.clientPortal.heading')}</h2>}
                >
                  <p>
                    <Trans i18nKey="customerCentre.clientPortal.body" t={t} />
                  </p>
                </InfoBlock>
              </div>
            </div>
            <div className="row row-wrap">
              <div className="column column-60">
                <InfoBlock
                  icon={IconTypes.HOUSE_IN_HAND}
                  buttonOptions={{
                    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.HOME_WARRANTY),
                    label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                    iconOptions: {
                      icon: IconTypes.ARROW_NEXT,
                    },
                    styleOptions: {
                      isInline: true,
                      size: ButtonLinkSize.SMALL,
                    },
                  }}
                  heading={<h2 className="h5">{t('customerCentre.encompass.heading')}</h2>}
                >
                  <p>{t('customerCentre.encompass.body')}</p>
                </InfoBlock>
              </div>
            </div>
            <div className="row row-wrap">
              <div className="column column-60">
                <InfoBlock
                  icon={IconTypes.RESCUE_RING}
                  buttonOptions={{
                    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.NEXTJOB_PROGRAM),
                    label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                    iconOptions: {
                      icon: IconTypes.ARROW_NEXT,
                    },
                    styleOptions: {
                      isInline: true,
                      size: ButtonLinkSize.SMALL,
                    },
                  }}
                  heading={<h2 className="h5">{t('customerCentre.nextJob.heading')}</h2>}
                >
                  <p>{t('customerCentre.nextJob.body')}</p>
                </InfoBlock>
              </div>
            </div>
            <div className="row row-wrap">
              <div className="column column-60">
                <InfoBlock
                  icon={IconTypes.DOLLAR}
                  buttonOptions={{
                    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.UNDERSTANDING_YOUR_MORTGAGE),
                    label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                    iconOptions: {
                      icon: IconTypes.ARROW_NEXT,
                    },
                    styleOptions: {
                      isInline: true,
                      size: ButtonLinkSize.SMALL,
                    },
                  }}
                  heading={<h2 className="h5">{t('customerCentre.prepayment.heading')}</h2>}
                >
                  <p>{t('customerCentre.prepayment.body')}</p>
                  <ul>
                    {(t('customerCentre.prepayment.list', { returnObjects: true }) as string[]).map(
                      (listItem) => (
                        <li key={listItem}>{listItem}</li>
                      )
                    )}
                  </ul>
                </InfoBlock>
              </div>
            </div>
            <div className="row row-wrap">
              <div className="column column-60">
                <InfoBlock
                  icon={IconTypes.FINANCIAL_TOOLS_RESOURCES}
                  buttonOptions={{
                    to: getPathFromRouteKey(
                      RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_TOOLS_RESOURCES
                    ),
                    label: t('customerCentre.higherInterest.buttonLabel'),
                    iconOptions: {
                      icon: IconTypes.ARROW_NEXT,
                    },
                    styleOptions: {
                      isInline: true,
                      size: ButtonLinkSize.SMALL,
                    },
                  }}
                  heading={<h2 className="h5">{t('customerCentre.higherInterest.heading')}</h2>}
                >
                  <p>{t('customerCentre.higherInterest.body')}</p>
                </InfoBlock>
              </div>
            </div>
          </MagicCarousel>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "generic-image-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footerImage: file(relativePath: { eq: "generic-image-4.jpg" }) {
      childImageSharp {
        fixed(width: 1400, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(CustomerCentre);
